import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from '../layouts/DefaultLayout'
import BlogPost from "../components/blogPost/BlogPost"

const TagTemplate = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges.map(({node: post}) => post)

  return (
    <DefaultLayout
      pageTitle={`Tag archive: ${pageContext.name}`}
    >
      {posts.map(post =>
        <BlogPost post={post} />
      )}
    </DefaultLayout>
  )
}

export default TagTemplate

export const query = graphql`
  query($id: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "post" } }
        fields: {
          tagIds: { in: [$id] }
        }
      },
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            date
            tags
            lang
          }
          fields {
            slug
            categories { id, name, url }
            tags { id, name, url }
          }
        }
      }
    }
  }
`
